<template>
  <div class="successful page">
    <div class="successful-content">
      <header>
        <i class="el-icon-check"></i>
        <h3>面试预约成功</h3>
      </header>
      <main>
        <h6>预约信息</h6>
        <ul>
          <li><label>真实姓名：</label><span>刘大锤</span></li>
          <li><label>联系方式：</label><span>15021761239</span></li>
          <li><label>面试岗位：</label><span>初级前端工程师</span></li>
          <li><label>期望薪资：</label><span>6000元/月</span></li>
          <li><label>预约时间：</label><span>2022年6月23日（明天，周五）晚上8点</span></li>
        </ul>
      </main>
      <footer>
        <p>站长提醒：面试期间请确保您在一个安静的环境并保持手机信号畅通！面试大约需要30至45分钟时间，请妥善安排好您的时间。</p>
        <button>模拟练习</button>
        <button>我的预约</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.successful{
  height: 520px;
  .successful-content{
    width: 455px;
    height: 100%;
    margin: 0 auto;
    padding: 45px 0;
    box-sizing: border-box;
    header{
      text-align: center;
      i{
        font-size: 90px;
        color: $primary-color;
      }
      h3{
        margin: 0 0 10px 0;
        font-size: 26px;
        font-weight: normal;
      }
    }
    main{
      height: 160px;
      border-bottom: 1px solid #E4E4E4;
      border-top: 1px solid #E4E4E4;
      h6{
        color: $font-color-third;
        font-size: 14px;
        margin: 12px 0 20px 0 ;
      }
      ul{
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li{
          font-size: 14px;
          width: 40%;
          margin-bottom: 13px;
          margin-left: 30px;
          &:last-of-type{
            margin-left: 30px;
            width: 100%;
          }
          label{
            color: $font-color-second;
          }
          span{
            font-weight: bolder;
          }
        }
      }
    }
    footer{
      p{
        color: $font-color-third;
        font-size: 12px;
        margin: 20px 0 35px 0;
      }
      button{
        width: 130px;
        height: 30px;
        border-radius: 30px;
        border: 1px solid $primary-color;
        color: $primary-color;
        margin-left: 82px;
        background: $background-color;
        &:last-of-type{
          margin-left: 30px;
          background: $primary-color;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
